<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Blue Tanks Goldies"
          class="shrink mr-2"
          contain
          src="/images/goldie.png"
          transition="scale-transition"
          width="40"
        />

        Blue Tanks
      </div>

      <v-spacer></v-spacer>

      {{ displayAccount }}
      <v-btn v-if="accounts.length === 0" @click="connectWallet" text>
        <span class="mr-2">Connect Wallet</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container class="text-center">
        <div class="mb-5">
          <div
            class="d-flex justify-center align-center flex-column"
            style="font-size: 42px; font-weight: bold"
          >
            Blue Tanks Goldies
            <v-img
              alt="Blue Tanks Goldies"
              class="mb-5"
              contain
              src="/images/goldie.png"
              transition="scale-transition"
              width="100"
            />
          </div>
          <div class="d-flex flex-wrap justify-center flex-row">
            <p class="text-justify mb-10 shrink">
              Blue Tanks Goldies are NFTs on the Ethereum blockchain. There will
              only ever be 10,000 randomly generated Goldies available for
              purchase. Goldies can have unique attributes, including headgear,
              fins, tails, mouths, eyes, and more. Goldies aren't very strong,
              but they can protect you when things get tough!
            </p>
            <v-slider
              v-model="mintNum"
              :label="sliderLabel"
              :thumb-color="'$ccc'"
              thumb-label="always"
              :min="1"
              :max="20"
            ></v-slider>
          </div>

          <div class="d-flex justify-center mb-8">
            <v-img
              alt="Blue Tanks Goldies"
              class="shrink mr-2"
              contain
              src="/images/eth.png"
              transition="scale-transition"
              width="15"
            />
            {{ mintCost / ethAmount }}
          </div>
          <h1>{{ this.minted }} / 10000</h1>
          <v-btn v-if="selectedAccount" color="primary" @click="mint">
            Mint
          </v-btn>
        </div>
      </v-container>
      <v-parallax
        src="/images/tankbackground.jpg"

        height="800"
      >
        <v-row align="start" justify="center">
          <v-col class="text-left" cols="12">
            <div class="align-self-start mt-5">
              <h1 class="text-center">FAQ</h1>

              <h3>What is Blue Tanks?</h3>
              <p>Blue Tanks is the name of the parent project for Goldies.</p>
              <br />
              <h3>What are Goldies</h3>
              <p>
                Goldies are some of the most popular pets around the world.
                Known a gentle creatures, they're not very strong, but they can
                provide good defense. When they swim in school, they provide an
                extra layer of protection to whoever has them.
              </p>
              <br />
              <h3>How man Goldies can I purchase?</h3>
              <p>There's no limit on the number an address can own, but you can only purchase a max of 20 at a time to try to make it fair!</p>
              <br />
              <h3>What is Blue Tanks</h3>
              <p>Blue Tanks is the name of the parent project for Goldies.</p>
              <br />
              <div style="position:relative">
             
               <goldie v-for="(item, index) in goldieCount" :key="index"></goldie>
               </div>
            </div>
          </v-col>
        </v-row>
      </v-parallax>
      <v-container>
        <h1 class="text-center">What can goldies do</h1>
        <p>Goldies like to swim.  That's what they do.  But when you have a lot of goldies, they can add some confusion to the mix.  Too many goldies in the water and predators don't know where exactly to attack.  They might pick some
          of them off one by one, but it's hard to tell if something is escaping behind the cloud of goldies.  These are good starter fish, not really agressive but they are really
          helpful when you need to get out of a pinch!  Goldies are not going to be minted again.  This is not V1 of goldies.  This is the only mint of them that will happen and they will be important
          down the road!
        </p>
      </v-container>
      <v-container v-if="goldies.length > 0">
        <h1>My Goldies</h1>
        <v-row>
          <v-col
            v-for="goldieId in goldies"
            :key="goldieId"
            class="d-flex child-flex"
          >
          <goldieNft :id="parseInt(goldieId)"></goldieNft>
          </v-col>
        </v-row>
      </v-container>
      </v-main>
    <v-footer class="" primary paddless>
      <v-card flat tile> Discord </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import Web3 from "web3";
import goldiesContract from "../../goldies/contract/build/contracts/Goldies.json";
import goldie from './components/Goldie.vue';
import goldieNft from './components/GoldieNft.vue';

export default {
  name: "App",

  components: {
    goldie,
    goldieNft
  },
  async mounted() {
    this.web3 = new Web3(window.ethereum);
    this.loadContract();
    await this.getAccounts();
    this.getGoldies();
  },
  data: () => ({
    ethereumLoaded: false,
    selectedAccount: null,
    accounts: [],
    wallet: null,
    cost: 30000000000000000,
    ethAmount: 1000000000000000000,
    contract: null,
    web3: null,
    minted: 0,
    mintNum: 1,
    goldieCount: 1,
    ipfsNode: null,
    goldies: [],
  }),
  methods: {
    async getGoldies() {
      this.goldies = await this.contract.methods.walletOfOwner(this.selectedAccount).call();
    },
    async connectWallet() {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      await this.getAccounts();
    },
    async getAccounts() {
      this.wallet = await this.web3.eth.accounts.wallet;
      this.accounts = await this.web3.eth.getAccounts();
      this.selectedAccount = this.accounts[0];
    },
    async mint() {
      const transaction = {
        from: this.selectedAccount,
        value: this.mintCost,
      };
      this.contract.methods
        .mint(this.selectedAccount, this.mintNum)
        .send(transaction)
        .catch((err) => {
          console.log(err);
        });
    },
    loadContract() {
      if (window.ethereum) {
        this.setupContract();
      } else {
        setTimeout(() => {
          this.loadContract();
        }, 300);
      }
    },
    async setupContract() {
      this.contract = new this.web3.eth.Contract(
        goldiesContract.abi,
        "0xe78A0F7E598Cc8b0Bb87894B0F60dD2a88d6a8Ab"
      );

      this.minted = await this.contract.methods.totalMint().call();
      this.contract.events.CreateGoldie({}, (error, event) => {
        this.minted = Number(event.returnValues.id) + 1;
        this.getGoldies();
      });
    },
  },
  computed: {
    mintCost() {
      return this.mintNum * this.cost;
    },
    displayAccount() {
      if (this.selectedAccount !== undefined && this.selectedAccount !== null)
        return (
          this.selectedAccount.substring(0, 5) +
          "..." +
          this.selectedAccount.substring(this.selectedAccount.length - 5)
        );
      return "";
    },
    sliderLabel() {
      return "Mint " + this.mintNum;
    },
  },
};
</script>
